// import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
// import { db } from "../firebase";
// import { toast } from "react-toastify";

function EnquiryForm() {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [model, setModel] = useState("");
  const [loading, setLoading] = useState(false);

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // setLoading(true);
    // const dataCollectionRef = collection(db, "enquiries");
    // if (name !== "" && phone !== "" && model !== "") {
    //   try {
    //     await addDoc(dataCollectionRef, {
    //       name: name,
    //       email: email,
    //       phone: phone,
    //       model: model,
    //       timestamp: dateTime,
    //     });
    //     toast.success("Form submitted successfully");
    //     setLoading(false);
    //     setName("");
    //     setEmail("");
    //     setPhone("");
    //     setModel("");
    //     navigate("/thank-you");
    //   } catch (error) {
    //     toast.error("Something went wrong!");
    //   }
    // } else {
    //   toast.error("Please fill all the fields");
    // }
    // setLoading(false);
  };

  const pattern = /^[6-9][0-9]{6,9}$/;
  if (phone !== "" && phone.length === 10) {
    if (!pattern.test(phone)) {
      //   toast.error("Enter valid phone number", {
      //     theme: "colored",
      //   });
    }
  }
  return (
    <div id="form">
      <p className="font-semibold text-xl tracking-wide mb-5">
        Vehicle Enquiry
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mt-3">
          <label htmlFor="name">
            Name <span className="text-red-600">*</span>
          </label>
          <input
            id="name"
            type="text"
            disabled={loading}
            className="border w-full rounded p-1.5 outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mt-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            disabled={loading}
            className="border w-full rounded p-1.5 outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mt-3">
          <label htmlFor="mobile">
            Mobile <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            id="mobile"
            disabled={loading}
            className="border w-full rounded p-1.5 outline-none"
            value={phone}
            minLength="10"
            maxLength="10"
            onChange={(e) =>
              setPhone(
                e.target.value.replace(/[^1-9]/g, "") &&
                  e.target.value.replace(/ /g, "")
              )
            }
          />
          {!pattern.test(phone) && phone.length === 10 && (
            <small className="text-red-500">phone number is invalid</small>
          )}
        </div>

        <div className="mt-3">
          <label htmlFor="">
            Model <span className="text-red-600">*</span>
          </label>
          <select
            value={model}
            disabled={loading}
            onChange={(e) => setModel(e.target.value)}
            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
          >
            <option>Select Model</option>
            <optgroup label="Arena">
              <option value="Alto 800">Maruti Alto</option>
              <option value="Alto K10">Maruti Alto K10</option>
              <option value="Wagon R">Maruti Wagon R</option>
              <option value="Celerio">Maruti New Celerio</option>
              <option value="Swift">Maruti Swift</option>
              <option value="Dzire">Maruti Dzire</option>
              <option value="S-Presso">Maruti S-Presso</option>
              <option value="Ertiga">Maruti Ertiga</option>
              <option value="New Brezza">Maruti New Brezza</option>
              <option value="Eeco">Maruti Eeco</option>
            </optgroup>
            <optgroup label="Nexa">
              <option value="Invicto">Maruti Invicto</option>
              <option value="Fronx">Maruti Fronx</option>
              <option value="Jimny">Maruti Jimny</option>
              <option value="Ciaz">Maruti Ciaz</option>
              <option value="XL6">Maruti XL6</option>
              <option value="Baleno">Maruti Baleno</option>
              <option value="Ignis">Maruti Ignis</option>
              <option value="Grand Vitara">Maruti Grand Vitara</option>
            </optgroup>
            <optgroup label="Goods Carrier">
              <option value="Super Carry">Super Carry</option>
              <option value="Eeco Cargo">Eeco Cargo</option>
            </optgroup>
          </select>
        </div>

        <p className="text-gray-700 mt-5 text-xs">
          <span className="text-black font-bold text-sm">Disclaimer</span>: I
          agree that by clicking the ‘Submit’ button below, I am explicitly
          soliciting a call/Message from its Representatives on my ‘Mobile’.
        </p>

        <div className="flex items-start mt-5">
          <input
            id="disclaimer"
            type="checkbox"
            required
            className="h-4 w-4 rounded"
          />
          <div className="ml-2 text-sm ">
            <label htmlFor="disclaimer" className="font-medium text-gray-700">
              Please agree with following Disclaimer
            </label>
          </div>
        </div>

        <button
          className={`${
            pattern.test(phone) && phone.length === 10
              ? "cursor-auto"
              : "cursor-not-allowed"
          } bg-red-500 hover:bg-red-400 text-white w-full text-center px-5 py-1.5  mt-5 rounded`}
          type="submit"
          disabled={pattern.test(phone) && phone.length === 10 ? false : true}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
              Loading...
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}

export default EnquiryForm;
