import { IoLocationOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const footer = {
  usefulLinks: [
    {
      name: "Arena Cars",
      link: "/channels/arena",
    },
    {
      name: "Nexa Cars",
      link: "/channels/nexa",
    },
    {
      name: "Commercial Vehicles",
      link: "/channels/commercial",
    },
    {
      name: "Suzuki",
      link: "/channels/suzuki",
    },
  ],
  services: [
    {
      name: "Book A Service",
      link: "/book-a-service",
    },
    {
      name: "Finance",
      link: "/finance",
    },
    {
      name: "Insurance",
      link: "/insurance",
    },
    {
      name: "Truevalue",
      link: "/truevalue",
    },
  ],
};

function Footer() {
  return (
    <>
      <div className="bg-gray-900 py-12">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 container px-5 md:px-0 mx-auto text-gray-200">
          <div className="col-span-1">
            <img
              src={require("../../assets/logo.png")}
              className="h-20"
              alt="logo"
            />
            <p className="flex items-start mt-3">
              <IoLocationOutline className="mr-2" fontSize={40} />
              Opp: KIMS Hospital, 64, Gachibowli - Miyapur Rd, Madhapur,
              Kondapur, Telangana 500084, India
            </p>
            <a
              href="tel:7093711199"
              className="flex items-center mt-3 hover:text-red-600"
            >
              <FaPhoneAlt className="mr-2" />
              99999 99999
            </a>
          </div>
          <div className="col-span-1 uppercase">
            <p className=" text-xl tracking-wider">Useful links</p>
            <p className="bg-[#2f2483] h-1 mt-2 max-w-[80px] rounded-full"></p>
            <div className="mt-7 space-y-3">
              {footer.usefulLinks.map((item, index) => (
                <div
                  className="flex border-b pb-2 border-gray-700 items-center hover:text-red-500 duration-200"
                  key={index}
                >
                  <IoIosArrowBack className="rotate-180" />
                  <Link to={item.link}>{item.name}</Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1 uppercase">
            <p className=" text-xl tracking-wider">services</p>
            <p className="bg-[#2f2483] h-1 mt-2 max-w-[80px] rounded-full"></p>
            <div className="mt-7 space-y-3">
              {footer.services.map((item, index) => (
                <div
                  className="flex border-b pb-2 border-gray-700 items-center hover:text-red-500 duration-200"
                  key={index}
                >
                  <IoIosArrowBack className="rotate-180" />
                  <Link to={item.link}>{item.name}</Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1">
            <p className=" text-xl tracking-wider uppercase">newsletter</p>
            <p className="bg-[#2f2483] h-1 mt-2 max-w-[80px] rounded-full"></p>
            <div className="mt-7 space-y-3">
              <p>
                Keep up on our always evolving products features and technology.
                Enter your e-mail and subscribe to our newsletter.
              </p>
              <form>
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="outline-none bg-gray-600 w-full border-none rounded p-2"
                />
                <button
                  type="submit"
                  className="bg-[#2f2483] text-white w-full py-2 hover:bg-red-600 duration-300 mt-3"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-footer bg-slate-900 text-white py-3">
        <div className="container mx-auto">
          <div className="text-center md:text-left md:flex items-center justify-between">
            <p>© Copyright {new Date().getFullYear()} GEM MOTORS PVT LTD.</p>
            <div className="flex items-center justify-center space-x-3 text-xs sm:text-base px-5 md:px-0">
              <Link className="hover:text-red-600" to="/contact-us">
                Contact Us
              </Link>
              <p className="w-px bg-white h-7"></p>
              <Link className="hover:text-red-600" to="/">
                Privacy Policy
              </Link>
              {/* <p className="w-px bg-white h-7"></p> */}
              {/* <Link className="hover:text-red-600" to="/">
                Powered by SR Creative Graphics
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
