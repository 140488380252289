// import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
// import { Helmet } from "react-helmet";
import { CgSpinner } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import Footer from "../../components/footer/Footer";
import Header from "../../components/navbar/Header";
// import { db } from "../firebase";

function Finance() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [outlet, setOutlet] = useState("");
  const [comments, setComments] = useState("");
  const [model, setModel] = useState("");
  const [purchaseTime, setPurchaseTime] = useState("");
  const [formLoanAmount, setFormLoanAmount] = useState("");
  const [loanDuration, setLoanDuration] = useState("");
  const [loading, setLoading] = useState(false);

  const pattern = /^[6-9][0-9]{6,9}$/;
  if (phone !== "" && phone.length === 10 && !pattern.test(phone)) {
    // toast.error("Enter valid phone number", {
    //   theme: "colored",
    // });
  }

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  //   const dataCollectionRef = collection(db, "finances");
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // try {
    //   await addDoc(dataCollectionRef, {
    //     name: name,
    //     email: email,
    //     phone: phone,
    //     outlet: outlet,
    //     comments: comments,
    //     model: model,
    //     purchaseTime: purchaseTime,
    //     formLoanAmount: formLoanAmount,
    //     loanDuration: loanDuration,
    //     timestamp: dateTime,
    //   });
    //   navigate("/thank-you");
    //   setLoading(false);
    // } catch (error) {
    //   toast.error("Something went wrong!");
    //   setLoading(false);
    // }
  };

  const [loanAmount, setLoanAmount] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");

  function calculateEMI() {
    const principal = parseFloat(loanAmount);
    const calculateInterest = parseFloat(interestRate) / 100 / 12;
    const calculatePayments = parseFloat(loanTerm) * 12;

    // calculate the monthly payment
    const x = Math.pow(1 + calculateInterest, calculatePayments);
    const monthly = (principal * x * calculateInterest) / (x - 1);
    const monthlyPayment = parseFloat(monthly).toFixed(2);

    // calculate interest
    const totalInterest = (monthly * calculatePayments - principal).toFixed(2);
    const totalPayment = (monthly * calculatePayments).toFixed(2);

    document.getElementById("month").innerHTML = parseFloat(monthlyPayment);
    document.getElementById("interest").innerHTML = totalInterest;
    document.getElementById("total").innerHTML = totalPayment;
  }

  return (
    <>
      <Header />
      <img
        src={require("../../assets/banners/Makers_Of_Suv_Web.jpeg")}
        className="w-full lg:h-[590px] object-cover"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Finance
        </p>
      </div>

      <div className="container mx-auto my-16 px-5">
        <div className="grid md:grid-cols-2 gap-4 px-5 mt-5">
          {/* new grid column opens */}
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <p className="uppercase font-bold text-md">my car details :</p>
              <div className="grid md:grid-cols-2 gap-3">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                    type="text"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Outlet
                  </label>
                  <select
                    onChange={(e) => setOutlet(e.target.value)}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option>Select Outlet City</option>
                    <option>Hyderabad</option>
                    <option>Secunderabad</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Phone <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                    type="text"
                    value={phone}
                    maxLength="10"
                    minLength="10"
                    required
                    onChange={(e) =>
                      setPhone(
                        e.target.value.replace(/[^1-9]/g, "") &&
                          e.target.value.replace(/ /g, "")
                      )
                    }
                  />
                  {!pattern.test(phone) && phone.length === 10 ? (
                    <small className="text-red-500">
                      phone number is invalid
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Comments
                </label>
                <textarea
                  className="border outline-none p-2 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                  type="text"
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>

              <p className="uppercase font-bold text-md">
                share loan requirements :
              </p>
              <div className="grid md:grid-cols-2 gap-3">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Model
                  </label>
                  <select
                    onChange={(e) => setModel(e.target.value)}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option>Select Model</option>
                    <optgroup label="Arena">
                      <option value="Alto 800">Maruti Alto</option>
                      <option value="Alto K10">Maruti Alto K10</option>
                      <option value="Wagon R">Maruti Wagon R</option>
                      <option value="Celerio">Maruti New Celerio</option>
                      <option value="Swift">Maruti Swift</option>
                      <option value="Dzire">Maruti Dzire</option>
                      <option value="S-Presso">Maruti S-Presso</option>
                      <option value="Ertiga">Maruti Ertiga</option>
                      <option value="Brezza">Maruti New Brezza</option>
                      <option value="Eeco">Maruti Eeco</option>
                    </optgroup>
                    <optgroup label="Nexa">
                      <option value="Ciaz">Maruti Ciaz</option>
                      <option value="XL6">Maruti XL6</option>
                      <option value="Baleno">Maruti Baleno</option>
                      <option value="Ignis">Maruti Ignis</option>
                      <option value="Grand Vitara">Maruti Grand Vitara</option>
                    </optgroup>
                    {/* <optgroup label="CNG">
                      <option value="Alto CNG">Maruti Alto CNG</option>
                      <option value="Wagon R CNG">Maruti Wagon R CNG</option>
                      <option value="Celerio CNG">
                        Maruti New Celerio CNG
                      </option>
                      <option value="Dzire CNG">Maruti Dzire CNG</option>
                      <option value="S-Presso CNG">Maruti S-Presso CNG</option>
                      <option value="Ertiga CNG">Maruti Ertiga CNG</option>
                      <option value="Eeco CNG">Maruti Eeco CNG</option>
                    </optgroup> */}
                    <optgroup label="Passenger Carrier">
                      <option value="Tour H1">Maruti Tour H1</option>
                      <option value="Tour H3">Maruti Tour H3</option>
                      <option value="Tour S">Maruti Tour S</option>
                      <option value="Tour V">Maruti Tour V</option>
                      <option value="Tour M">Maruti Tour M</option>
                    </optgroup>
                    <optgroup label="Goods Carrier">
                      <option value="Super Carry">Super Carry</option>
                      <option value="Eeco Cargo">Eeco Cargo</option>
                    </optgroup>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Purchase Time
                  </label>
                  <select
                    onChange={(e) => setPurchaseTime(e.target.value)}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option>Select Purchase Time</option>
                    <option>Immediate</option>
                    <option>2 Weeks</option>
                    <option>3 Weeks</option>
                    <option>4 Weeks</option>
                    <option>4-6 Weeks</option>
                    <option>6+ Weeks</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Loan Amount
                  </label>
                  <input
                    className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                    type="text"
                    onChange={(e) => setFormLoanAmount(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Loan Duration
                  </label>
                  <input
                    className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                    type="text"
                    onChange={(e) => setLoanDuration(e.target.value)}
                  />
                </div>
              </div>
              <p className="text-gray-700">
                <span className="text-black font-bold">Disclaimer</span>: I
                agree that by clicking the ‘Submit’ button below, I am
                explicitly soliciting a call/Message from Gem Motors Pvt. Ltd or
                its Representatives on my ‘Mobile’.
              </p>
              <div className="flex items-start">
                <input
                  id="disclaimer"
                  name="disclaimer"
                  type="checkbox"
                  className="h-4 w-4 rounded"
                  required
                />
                <div className="ml-2 text-sm">
                  <label
                    htmlFor="disclaimer"
                    className="font-medium text-gray-700"
                  >
                    Please Agree with following Disclaimer
                  </label>
                </div>
              </div>
              <button
                className="bg-purple-900 text-white rounded py-2.5 px-5"
                type="submit"
                disabled={
                  pattern.test(phone) && phone.length === 10 ? false : true
                }
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                    Loading
                  </div>
                ) : (
                  "Get Your Loan Now"
                )}
              </button>
            </div>
          </form>

          {/* new grid column opens */}
          <div>
            <p className="text-2xl font-bold mb-7">EMI Calculator</p>
            <div className="flex items-center justify-between mt-2">
              <p>Curreny :</p>
              <select
                disabled
                name="currency"
                className="block py-2 w-full max-w-[75%] px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option>Select Curreny</option>
                <option selected>₹ INR</option>
                <option>$ USD</option>
              </select>
            </div>
            <div className="flex items-center justify-between mt-2">
              <p>Loan Amount :</p>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full max-w-[75%] focus:ring-blue-500 focus:border-blue-500"
                type="text"
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-between mt-2">
              <p>Interest Rate :</p>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full max-w-[75%] focus:ring-blue-500 focus:border-blue-500"
                type="text"
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between mt-2">
              <p>Years :</p>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full max-w-[75%] focus:ring-blue-500 focus:border-blue-500"
                type="text"
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
              />
            </div>
            <div className="flex justify-center mt-3">
              <button
                className="bg-purple-900 text-white rounded py-2.5 px-5"
                type="submit"
                onClick={calculateEMI}
              >
                Submit
              </button>
            </div>

            <div className="mt-8">
              <div className="flex items-center space-x-3 mt-2">
                <p>Loan EMI :</p>
                <p id="month"></p>
              </div>
              <div className="flex items-center space-x-3 mt-2">
                <p>Total Interest Payable :</p>
                <p id="interest"></p>
              </div>
              <div className="flex items-center space-x-3 mt-2">
                <p>Total Payment(Principal + Interest) :</p>
                <p id="total"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Finance;
