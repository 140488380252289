import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Detail from "./screens/Detail";
import Thankyou from "./screens/Thankyou";
import BookAService from "./screens/services/book";
import Finance from "./screens/services/finance";
import Insurance from "./screens/services/insurance";
import Truevalue from "./screens/services/truevalue";
import Contact from "./screens/contact";
import Channel from "./screens/Channel";

function App() {
  const ScrollTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };
  return (
    <>
      <ScrollTop />
      <div className="hidden">
        Developed by Rajesh Allala Powered by SR Creative Graphics. Reach out to
        us: 96522 98089
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vehicles/:id" element={<Detail />} />
        <Route path="/channels/:id" element={<Channel />} />
        <Route path="/book-a-service" element={<BookAService />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/truevalue" element={<Truevalue />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="*" element={"404 - Not Found"} />
        <Route path="/not-found" element={"404 - Not Found"} />
      </Routes>
    </>
  );
}

export default App;
