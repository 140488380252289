import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { IoCloseOutline } from "react-icons/io5";
import { BiMenuAltRight } from "react-icons/bi";

import { Link } from "react-router-dom";

const navigations = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Arena",
    href: "/channels/arena",
  },
  {
    name: "Nexa",
    href: "/channels/nexa",
  },
  {
    name: "Commercial",
    href: "/channels/commercial",
  },
  {
    name: "Suzuki",
    href: "/channels/suzuki",
  },
  {
    name: "Truevalue",
    href: "/truevalue",
  },
  {
    name: "Finance",
    href: "/finance",
  },
  {
    name: "Insurance",
    href: "/insurance",
  },
  {
    name: "Book A Service",
    href: "/book-a-service",
  },
  {
    name: "Contact",
    href: "/contact-us",
  },
];

function MobileHeader() {
  // const [navbar, setNavbar] = useState(false);
  // const fixedNavbar = () => {
  //   if (window.scrollY >= 100) {
  //     setNavbar(true);
  //   } else {
  //     setNavbar(false);
  //   }
  // };
  // window.addEventListener("scroll", fixedNavbar);
  return (
    <>
      <Popover className="relative lg:hidden z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-2 lg:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <img
                  className="h-16 w-auto"
                  src={require("../../assets/logo.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none outline-none">
                <span className="sr-only">Open menu</span>
                <BiMenuAltRight className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-16 w-auto"
                      src={require("../../assets/logo.png")}
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none outline-none ">
                      <span className="sr-only">Close menu</span>
                      <IoCloseOutline className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {navigations.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}

export default MobileHeader;
